/* You can add global styles to this file, and also import other style files */
$bg-body: #ffffff;
html, body { height: 100%; background-color: $bg-body;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~   MENÚ  ~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
$color-gc: #bda235;
mat-drawer-container {
  width: 100%;
  height: 100%;

  //Header
  #header{
    background-image: linear-gradient(160deg,#f8f5ea 0%, #d7c785 50%, #bda235  90%);
    height: 30px;  
  }
  
  //Menu
  mat-drawer{
    width: 260px !important;
    
    padding: 10px;
    text-align: center;
    
    button{
      color: #6d6c6c !important;
      font-weight: 100 !important;
      //font-size: 15px !important;
    }

    .mat-expansion-panel:not([class*=mat-elevation-z]) {
      box-shadow: none !important;
    }

    mat-expansion-panel{
      mat-expansion-panel-header{
        mat-panel-title{
          // color: #6d6c6c !important;
          font-weight: 100 !important;
        }
      }
      button{
        // color: #6d6c6c !important;
        mat-icon{
          font-size: 14px !important;
        }
      }
    }
    // .mat-expansion-panel-header{
    //   padding: 0 18px;
    //   color: #6d6c6c !important;
    // }
  }
  
  //Contenido de la página
  mat-drawer-content {
    
    // padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
mat-expansion-panel-header.submenus{
  padding: 0 5px 0 5px !important;
  letter-spacing: 1px;
  mat-icon {
    font-size: 18px;
    margin-right: 8px;
  }  
}
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~   END MENÚ  ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~   COLOR CALLOUT  ~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/* Base styles (regardless of theme) */
.bs-callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;    
  border-left: 5px solid #eee;
}
.bs-callout h4 {
  margin-top: 0;
}
.bs-callout p:last-child {
  margin-bottom: 0;
}
.bs-callout code,
.bs-callout .highlight {
  background-color: #fff;
}

/* Themes for different contexts */
.bs-callout-success {
  background-color: #e9ffe8;
  border-color: #198754;
}
.bs-callout-secondary {
  background-color: #f9f9f9;
  border-color: #6c757d;
}
.bs-callout-danger {
  background-color: #fcf2f2;
  border-color: #dc3545;
}
.bs-callout-warning {
  background-color: #fffbe8;
  border-color: #ffc107;
}
.bs-callout-info {
  background-color:#e8f5ff;
  border-color: #0dcaf0;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~   END COLOR CALLOUT  ~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/



@media (min-width: 768px) {
  .w-sm-auto-important {
      width: auto !important;
  }
}



.cursor-pointer {
  cursor: pointer;
}



button.tooltip-icon {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  line-height: 0;
  mat-icon {
      margin: 0;
      padding: 0;
      font-size: 20px;
  }
}


mat-icon.icon-small {
  margin: 0;
  padding: 0;
  font-size: 18px;
}


.adjust-w-c {
  width: calc(100% - 1px);
  margin-left: 1px;
}



.single-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.form-validation-error {
  color: #f44336;
  font-size: 11px;
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~   IMPRIMIR  ~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.content-print {
  display: none;
}

@media print {
  @page {
    margin: 0;
  }

  body {
    margin: 0;
  }

  body * {
    visibility: hidden;
  }

  #transactionPrintContent, #transactionPrintContent * {
    visibility: visible;
  }

  #transactionPrintContent {
    position: absolute;
    left: 0;
    top: 0;
    border: none;
    margin: 0;
    padding: 0;    
  }
}



/* Aplicar un borde a todas las celdas de la tabla */
.table-mat th, .table-mat td {
  border: 1px solid #e2e2e2; /* Cambia el color del borde según tus necesidades */
  padding: 8px; /* Añade relleno para mejorar la legibilidad */
}

/* Aplicar un borde a la tabla */
.table-mat {
  border-collapse: collapse; /* Asegura que los bordes no se dupliquen */
  width: 100%; /* Asegura que la tabla use todo el ancho disponible */
}

/* Aplicar un color de fondo al pasar el cursor sobre una fila y cambiar el borde a azul */
.table-mat tr.mat-mdc-header-row {
  font-weight:700;
}

.table-mat tr.mat-mdc-row:hover {
  background-color: #ececec !important; /* Puedes cambiar este color según tus necesidades */
  transition: background-color 0.3s ease; /* Transición suave para el cambio de color del borde */
}

/* Opcional: Aplicar un color de fondo diferente a las filas impares */
.table-mat tr.mat-mdc-row:nth-child(odd) {
  background-color: #fafafa; /* Cambia este color según tus necesidades */
}

/* Opcional: Aplicar un color de fondo diferente a las filas pares */
.table-mat tr.mat-mdc-row:nth-child(even) {
  background-color: #ffffff; /* Cambia este color según tus necesidades */
}



/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~   MENSAJE DE VALIDACIÓN  ~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
mat-form-field.content-error{
  position: relative !important;
  .msg-error{
      font-size: 11px;
      position: absolute;
      right: -10px;
      bottom: -4px;
      color: rgb(220,53,69);
      z-index: 999;
  }
}



/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~ IMAGEN DE FONDO   ~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.logo{
  width: 100%;
}

.background-div {
  width: 100%;
  height: 100%;
  background-image: url('./assets/image/bg-login.jpg');
  background-size: cover; /* Ajusta la imagen para cubrir el div */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-position: center; /* Centra la imagen en el div */
}




//.button-success { color: #149c08; } /* Cambia esto al color que desees */
//.button-success mat-icon { color: inherit; } /* Hace que el icono herede el color del botón */

.custom-mat-mini-fab-success {
  background-color: #198754 !important; /* Cambia el color de fondo */
  color: white; /* Cambia el color del icono */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Agrega sombra */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transición para hover */
}

.custom-mat-mini-fab-success:hover {
  background-color: #198754; /* Color de fondo al hacer hover */
  //transform: scale(1.1); /* Escala el botón al hacer hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Intensifica la sombra al hacer hover */
}

.custom-mat-mini-fab-success:disabled {
  background-color: #9E9E9E; /* Color de fondo para estado deshabilitado */
  color: #BDBDBD; /* Color del icono para estado deshabilitado */
  box-shadow: none; /* Elimina la sombra en estado deshabilitado */
}


.custom-mat-mini-fab-danger {
  background-color: #dc3545 !important; /* Cambia el color de fondo */
  color: white; /* Cambia el color del icono */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Agrega sombra */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transición para hover */
}

.custom-mat-mini-fab-danger:hover {
  background-color: #dc3545; /* Color de fondo al hacer hover */
  //transform: scale(1.1); /* Escala el botón al hacer hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Intensifica la sombra al hacer hover */
}

.custom-mat-mini-fab-danger:disabled {
  background-color: #9E9E9E; /* Color de fondo para estado deshabilitado */
  color: #BDBDBD; /* Color del icono para estado deshabilitado */
  box-shadow: none; /* Elimina la sombra en estado deshabilitado */
}